import { createPinia, defineStore, setActivePinia } from 'pinia'

setActivePinia(createPinia())

export const useMBTV = defineStore('mbtv', () => {
  const token = ref<string | null>(window.localStorage.getItem('mbtv_token'))
  const isLoading = ref<boolean>(true)

  const isAuthenticated = ref<boolean>(false)

  const config = useRuntimeConfig()

  const mbtv = new MBTV({
    client_id: config.public.mbtvClient,
    host: config.public.mbtvUrl,
    redirect_url: `${window.origin}/leseplaene`
  })

  mbtv.init({
    token: token.value ?? undefined,
  }).then((auth) => {
    if (auth) {
      setTokens()
      isAuthenticated.value = true
    }
    isLoading.value = false
  }).catch((_) => {
    isLoading.value = false
    clearTokens()
  })

  function setTokens() {
    window.localStorage.setItem('mbtv_token', mbtv.token ?? '')
  }

  function clearTokens() {
    window.localStorage.removeItem('mbtv_token')
    token.value = null
    isAuthenticated.value = false
  }

  async function login() {
    mbtv.login()
  }

  async function logout() {
    if (!isAuthenticated.value) return
    await mbtv.logout()
    clearTokens()
    window.location.reload()
  }

  async function waitForMBTV() {
    await until(isLoading).toBe(false)
  }

  async function updateToken() {
    if (isAuthenticated.value)
      mbtv.refreshToken(mbtv.token).then((refresh) => {
        if (refresh) {
          setTokens()
        }
        else {
          clearTokens()
        }
      }).catch(() => {
        clearTokens()
      })
  }

  return {
    login,
    logout,
    isAuthenticated,
    waitForMBTV,
    updateToken,
    mbtv
  }
})