import Keycloak from 'keycloak-js'
import { createPinia, defineStore, setActivePinia } from 'pinia'

setActivePinia(createPinia())

export const useKeycloak = defineStore('keycloak', () => {
  const token = ref<string | null>(window.localStorage.getItem('token'))
  const refreshToken = ref<string | null>(window.localStorage.getItem('refreshToken'))
  const isLoading = ref<boolean>(true)

  const isAuthenticated = ref<boolean>(false)

  const config = useRuntimeConfig()

  const keycloak = new Keycloak({
    url: config.public.keycloakUrl,
    realm: config.public.keycloakRealm,
    clientId: config.public.keycloakClientId,
  })

  keycloak.onTokenExpired = () => {
    updateToken()
  }

  keycloak
    .init({
      checkLoginIframe: false,
      token: token.value ?? undefined,
      refreshToken: refreshToken.value ?? undefined,
      redirectUri: `${window.origin}/`,
    })
    .then((auth) => {
      if (auth) {
        setTokens()
        isAuthenticated.value = true
      }
      isLoading.value = false
    })
    .catch((_) => {
      clearTokens()
      // window.location.reload()
    })

  function setTokens() {
    window.localStorage.setItem('token', keycloak.token ?? '')
    window.localStorage.setItem('refreshToken', keycloak.refreshToken ?? '')
  }

  function clearTokens() {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('refreshToken')
    token.value = null
    refreshToken.value = null
  }

  async function login() {
    await keycloak.login()
  }

  async function logout() {
    if (!isAuthenticated.value) return
    keycloak.logout()
    clearTokens()
    isAuthenticated.value = false
  }

  async function waitForKeycloak() {
    await until(isLoading).toBe(false)
  }

  async function updateToken() {
    keycloak.updateToken().then((refresh) => {
      if (refresh) {
        setTokens()
      }
    }).catch(() => {
      // window.location.reload()
    })
  }

  return {
    keycloak,
    login,
    logout,
    isAuthenticated,
    waitForKeycloak,
    updateToken
  }
})
