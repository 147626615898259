import { type AppRouter } from '@bibeltv-leseplaene/api'
import { httpLink } from '@trpc/client'
import superjson from 'superjson'
import { createTRPCNuxtClient } from 'trpc-nuxt/client'

export default defineNuxtPlugin({
  enforce: 'pre',
  setup() {
    const config = useRuntimeConfig()
    const client = createTRPCNuxtClient<AppRouter>({
      transformer: superjson,
      links: [
        httpLink({
          url: `${config.public.apiBaseUrl}/trpc`,
          headers: () => {
            const store = useKeycloak()
            const mbtv = useMBTV()
            return {
              authorization: store.isAuthenticated ? `Keycloak ${store.keycloak.token}` : mbtv.isAuthenticated ? `Bearer ${mbtv.mbtv.token ?? ''}` : '',
            }
          },
          fetch: async (url, options): Promise<Response> => {
            const res = await fetch(url, options);

            // if the response is a multi-status, we need to check all the responses
            if (res.status === 207) {
              const responses = await res.json();
              // if any of the responses is an unauthorized
              if (responses.some((r: any) => r.error.data.httpStatus === 401)) {
                // then try to rerun all the requests after auth (some of them might not be UNAUTHORIZED, but not the end of the world)
                return await handleTrpcUnauthError(res, url, options);
              }
            }

            // in this case all the batched requests have the same code, the the whole batch can be handled
            if (res.status === 401) {
              return await handleTrpcUnauthError(res, url, options);
            }

            // if nothing happens, carry on with the procedure
            return res;
          },
        }),
      ],
    })

    return {
      provide: {
        trpc: client,
      },
    }
  },
})
function handleTrpcUnauthError(res: Response, url: URL | RequestInfo, options: unknown): Response | PromiseLike<Response> {
  const keycloak = useKeycloak()
  const mbtv = useMBTV()

  keycloak.updateToken()
  mbtv.updateToken()
  return res
  // throw new Error('Function not implemented.')
}
