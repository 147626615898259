import axios from 'axios'

type MBTVResponse = {
  access_token: string
  access_token_valid_until: number
  refresh_token_valid_until: number
  userId: number
  client: string
}

export class MBTV {
  #host = ''
  #client_id = ''
  #redirect_url = ''
  #route = useRoute()
  #router = useRouter()

  token = ''

  constructor(config: {
    host: string
    client_id: string
    redirect_url: string
  }) {
    this.#host = config.host
    this.#client_id = config.client_id
    this.#redirect_url = config.redirect_url
  }

  async init(config: {
    token?: string
  }) {
    if (config.token) {
      this.token = config.token ?? ''
      return true
    }
    else {
      const hash = this.#route.hash
      const [key, token] = hash.replace('#', '').split('=')
      if (key === 'token' && token !== '') {
        try {
          await this.refreshToken(token)
          this.#router.replace({
            name: this.#route.name,
            params: this.#route.params,
            query: this.#route.query,
          })
          return true
        }
        catch (_) {}
      }
    }

    return false
  }

  login() {
    window.open(`${this.#host}/login#redirect_client=${this.#client_id}&referer=${this.#redirect_url}`, '_self')
  }

  async logout() {
    this.token = ''
    const token = useCookie('mbtv_token', {
      secure: true
    })
    token.value = null
    const refresh = useCookie('mbtv_refreshToken', {
      secure: true
    })
    refresh.value = null
    await axios.post(`${this.#host}/v2/account/logout`, {
      client: this.#client_id
    })
  }

  async refreshToken(token?: string) {
    try {
      const response = await axios.post<MBTVResponse>(`${this.#host}/v2/token/refresh`, {
        client: this.#client_id,
        token,
      })

      if (response.status === 200) {
        const tokenCookie = useCookie('mbtv_token', {
          maxAge: response.data.access_token_valid_until,
          secure: true,
        })
        tokenCookie.value = response.data.access_token
        this.token = response.data.access_token
        return true
      }
      return false
    }
    catch (err) {
      return false
    }
  }
}